<template>
  <div class="register">
    <div class="header">
      <div class="content">
        <div class="logo">199</div>
        ｜
        <div class="menu">重置密码</div>
      </div>
    </div>
    <div class="register-container">
      <div class="box">
        <el-form
          @submit.native.prevent
          :model="registerModel"
          :rules="rules"
          label-width="100px"
          ref="registerForm"
        >
          <el-form-item label="注册邮箱" prop="email">
            <el-input
              type="email"
              v-model="registerModel.email"
              autocomplete="off"
              class="bold"
              placeholder="输入注册邮箱"
            >
              <el-button slot="append" @click="handleSendEmail"
                >发送邮件</el-button
              ></el-input
            >
          </el-form-item>
          <el-form-item label="邮箱验证码" prop="verifyCode">
            <el-input
              type="email"
              v-model="registerModel.verifyCode"
              autocomplete="off"
              class="bold"
              placeholder="输入邮箱验证码"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="pwd">
            <el-input
              type="password"
              v-model="registerModel.pwd"
              autocomplete="off"
              placeholder="密码由数字和字母组成，最少6位"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认新密码" prop="confirmPwd">
            <el-input
              type="password"
              v-model="registerModel.confirmPwd"
              autocomplete="off"
              placeholder="密码由数字和字母组成，最少6位"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div
              :class="['next-but', nextStep ? 'active' : '']"
              @click="submitForm"
            >
              确认
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="hoho-footer">© 199 2018-2023</div>
  </div>
</template>

<script>
import Register from 'api/register'
import { vxRule, rules } from '@/utils/validator'
export default {
  components: {},
  name: '',
  data() {
    let verifyRegisterEmail = (rule, value, callback) => {
      if (value) {
        let { result, errMsg } = rules.Email(value)
        if (result) {
          callback()
        } else {
          callback(new Error(errMsg))
        }
      } else {
        callback(new Error('请输入您的注册邮箱'))
      }
    }
    let verifyPassword = (rule, value, callback) => {
      if (value) {
        let { result } = rules.Encode2(value)
        if (result) {
          if (value.length < 6) {
            callback(new Error('密码长度不能小于6位'))
          } else {
            callback()
          }
        } else {
          callback(new Error('密码只能由字母和数字组成'))
        }
      } else {
        callback(new Error('请输入新密码'))
      }
    }
    let verifyConfirmPassword = (rule, value, callback) => {
      if (value) {
        if (this.registerModel.pwd != value) {
          callback(new Error('新密码与确认新密码不一致'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请输入确认新密码'))
      }
    }
    return {
      nextStep: true,
      steps: 0,
      registerModel: {
        email: '', // 邮箱
        verifyCode: '', // 邮箱验证码
        pwd: '', // 新密码
        confirmPwd: '' // 确认新密码
      },
      rules: {
        email: [
          { validator: verifyRegisterEmail, required: true, trigger: 'change' }
        ],
        verifyCode: vxRule(true, null, 'blur', '请输入邮箱验证码'),
        pwd: [{ validator: verifyPassword, required: true, trigger: 'blur' }],
        confirmPwd: [
          { validator: verifyConfirmPassword, required: true, trigger: 'blur' }
        ]
      },
      sending: false
    }
  },
  computed: {},
  watch: {},
  created() {
    this.registerModel.email = this.$route.query.email
  },
  mounted() {},
  destroyed() {},
  methods: {
    submitForm() {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          Register.resetPwd({
            email: this.registerModel.email,
            code: this.registerModel.verifyCode,
            pwd: this.registerModel.confirmPwd,
            source: 'forget'
          }).then((res) => {
            if (res.success) {
              this.$message.success('密码已修改')
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleSendEmail() {
      if (!this.sending) {
        this.sending = true
        Register.sendEmail({
          email: this.registerModel.email,
          source: 'forget'
        }).then((res) => {
          this.sending = false
          if (res.success) {
            this.$message.success('邮件已发送')
          } else {
            this.$message.error(res.message)
          }
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.register {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .header {
    background: rgb(77, 28, 138);
    height: 75px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      width: 970px;
      display: flex;
      align-items: center;
      font-size: 17px;
      .logo {
        font-size: 28px;
        font-weight: bold;
        font-family: Tahoma, Helvetica, Arial;
        margin-right: 10px;
      }
      .menu {
        margin-left: 10px;
      }
    }
  }
  .register-container {
    flex: 1;
    width: 970px;
    background: #fff;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .box {
      width: 600px;
      border: 1px solid #ddd;
      padding: 60px 40px 40px;
      border-radius: 10px;
      margin-bottom: 40px;
      .bold {
        font-weight: bold;
      }
      .next-but {
        height: 36px;
        color: #fff;
        background: #ff6200;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        opacity: 0.5 !important;
        cursor: pointer !important;
        font-weight: bold;
      }
      .next-but.active {
        opacity: 1 !important;
      }
      .forgetpwd {
        margin-left: 100px;
        font-size: 12px;
        .but {
          color: rgb(77, 28, 138);
          margin-left: 2px;
          cursor: pointer !important;
        }
      }
    }
  }
}
.hoho-footer {
  background: rgb(77, 28, 138);
  color: #fff;
  text-align: center;
  padding: 10px;
  font-size: 12px;
}
::v-deep {
  .el-input {
    width: 400px;
  }
  .el-form-item__label {
    font-weight: bold;
  }
}
</style>
