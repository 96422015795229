import { netpost } from '../plugins/request'

export default {
  // 查询邮箱是否已注册
  existEmail(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/ExistEmail`, params)
  },
  // 发送邮件
  sendEmail(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/SendEmail`, params)
  },
  // 注册
  register(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/register`, params)
  },
  // 验证
  verifySendCode(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/verifySendCode`, params)
  },
  // 重置密码
  resetPwd(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/ResetPwd`, params)
  }
}
